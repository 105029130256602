var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section section--fullScreen section--flexCenter" },
    [
      _vm.step === 1
        ? _c("div", { staticClass: "container container--xxs" }, [
            _c(
              "form",
              {
                staticClass: "form formLayout",
                attrs: { name: "forgot", autocomplete: "off" },
              },
              [
                _c(
                  "div",
                  { staticClass: "form__title" },
                  [
                    _c("icon", {
                      staticClass: "icon-logo",
                      attrs: {
                        name: "logo-icon",
                        width: "100%",
                        height: "52",
                        original: "",
                      },
                    }),
                    _c("h3", [_vm._v("Reset your password")]),
                    _c("p", { staticClass: "p--lead" }, [
                      _vm._v(
                        "Enter your email address below and we'll send you an email with instructions to reset your password."
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "formGroup" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: "enter e-mail",
                      required: "",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm.error
                  ? _c("p", { staticClass: "form__error" }, [
                      _vm._v(_vm._s(_vm.error.toString())),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form__submit" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn--fullWidth btn--primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.forgotPassword(_vm.email)
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "form__footer" },
              [
                _vm._v("Return to"),
                _c(
                  "router-link",
                  { staticClass: "link", attrs: { to: { name: "Login" } } },
                  [_vm._v(" Log in")]
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "container container--xxs" }, [
            _c("h1", [_vm._v("Check your email!")]),
            _c("p", [
              _vm._v("We've sent you the link to reset your password."),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }